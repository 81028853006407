<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-10 15:56:13
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-16 17:36:55
-->
<template>
  <div class="main">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>
        <span @click="handleToHome" class="activeBreadColor">{{usageName}}</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="i in data" :key="i.name">
        <span
          @click="bread(i)"
          :class="i.Name && usageStyleId==1?'activeBreadColor':'BreadColor'"
        >{{i.name}}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: { data: [] },
  computed: {
    usageStyleId() {
      return this.$store.state.globalConfig.usageStyleId;
    },
    usageName() {
      return this.$store.state.defaultlanDetail.usageName;
    },
  },
  methods: {
    bread(i) {
      if (this.usageStyleId == 1) {
        if (i.Name) {
          this.$router.push({
            name: i.Name,
            params: i.params,
            query: {
              id: i.query,
            },
          });
        }
      }
    },
    handleToHome() {
      this.$router.push({
        name: "Home",
        params: this.$route.params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-breadcrumb__inner {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    // color: rgba(0, 0, 0, 0.8);
    letter-spacing: -0.2px;
    font-weight: 400;
    z-index: 10000;
    line-height: 24px;
  }
}
.BreadColor {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
}
.activeBreadColor {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
</style>