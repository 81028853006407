<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-04 09:56:50
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-29 11:18:50
-->
<template>
  <div class="search">
    <div class="breadcrumb">
      <BreadcrumbVue :data="Breadcrumbdata" />
    </div>
    <div class="art-list">
      <SeachResult :searchData="searchData" :searchValue="searchReault"></SeachResult>
    </div>
    <Page @pageIndex="pageChange" :total="searchData.total" :pagesize="pageSize" />
  </div>
</template>

<script>
import Page from "./module/page.vue";
import BreadcrumbVue from "@/components/Breadcrumb.vue";
import SeachResult from "./module/SeachResult.vue";
export default {
  name: "searchResult",
  components: { SeachResult, Page, BreadcrumbVue },
  data() {
    return {
      searchValue: "",
      isPageShow: false,
      Breadcrumbdata: [],
      pageIndex: 1,
      pageSize: 10,
      searchData: {
        current: 0,
        pages: 0,
        records: [],
        size: 0,
        total: 0,
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
    usageManageId() {
      return this.$store.state.wikiAppId;
    },
    appId() {
      return this.$store.state.defaultlanDetail.appId;
    },
    searchReault() {
      return this.$route.query.searchValue;
    },
  },
  watch: {
    language() {
      this.findArticle();
    },
    $route() {
      this.findArticle();
    },
  },
  mounted() {
    this.Breadcrumbdata = [
      {
        name: "搜索结果",
        path: "",
      },
    ];
    this.findArticle();
  },
  methods: {
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.findArticle();
    },
    findArticle() {
      var params = {
        articleInfo: this.$route.query.searchValue,
        appId: this.appId,
        usageManageId: this.usageManageId,
        language: this.language,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      this.$api.findArticle(params).then((res) => {
        if (res.code == 200 && res.data) {
          this.searchData = res.data;
          this.isPageShow = true;
        } else {
          this.searchData = {
            current: 0,
            pages: 0,
            records: [],
            size: 0,
            total: 0,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-top: 32px;
  margin-bottom: 24px;
}
.search {
  max-width: 1200px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 30px auto;
}
</style>
