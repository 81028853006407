<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-20 17:14:03
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-29 11:16:30
-->
<template>
  <div class="SeachResult">
    <div class="result-content">
      <div class="search-result">
        <div class="title">{{ $t("search.SerachResult.SerachResultTitle") }}</div>
        <div class="label">
          “{{ $route.query.searchValue }}”{{ $t("search.SerachResult.SerachResultDesctwo") }}
          <span
            class="theme-color"
          >{{ searchData.total}}</span>
          {{ $t("search.SerachResult.SerachResultNum") }}
        </div>
      </div>
      <div class="result-list">
        <div
          class="result-item"
          v-for="(i, index) in searchData.records"
          :key="index"
          @click="handleToArt(i, i.usageCategoryId,i.usageGroupId)"
        >
          <div class="result-title">{{ i.articleName }}</div>
          <div class="result-remark">{{ i.articleDescribe }}</div>
        </div>
        <!-- 404 -->
        <div class="no-data" v-if="!searchData.total">
          <img class="svg-icon" src="@/assets/search-empty.png" alt srcset />
          <div class="title">{{ $t("search.SerachResult.SerachResultTipTitle") }}</div>
          <div class="remark">{{ $t("search.SerachResult.SerachResultTipRemark") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SeachResult",
  props: {
    searchData: {},
    searchValue: {},
  },
  data() {
    return {
      value: this.searchValue,
    };
  },
  watch: {
    searchValue(newval) {
      this.value = newval;
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleToArt(item, id, usageGroupId) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: { articleId: item.articleId, usageCategoryId: id, usageGroupId },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.result-content {
  background: #ffffff;
  min-height: 600px;
  box-shadow: 0 2px 24px 0 rgba(0, 77, 193, 0.08);
  border-radius: 8px;
  padding: 28px 32px;
  .search-result {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .title {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.8);
      letter-spacing: -0.32px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .label {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: -0.2px;
      line-height: 21px;
      font-weight: 400;
    }
  }
  .result-list {
    .result-item {
      cursor: pointer;
      padding: 24px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      word-break: break-all;
      .result-title {
        font-size: 19px;
        color: #000000;
        letter-spacing: -0.28px;
        font-weight: 500;
        margin-bottom: 12px;
      }
      .result-remark {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: -0.23px;
        line-height: 28px;
        font-weight: 400;
        min-height: 55px;
      }
    }
    .no-data {
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .svg-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
      }
      .title {
        font-size: 17px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .remark {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}
</style>
