<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-12 14:13:22
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2022-12-16 10:15:59
-->
<template>
  <div class="main">
    <el-pagination background v-show="total > 0" layout="prev, pager, next" :total="total" :page-size="pagesize" @current-change="changePage"></el-pagination>
  </div>
</template>

<script>
export default {
  props: { total: {}, pagesize: {} },
  methods: {
    changePage(data) {
      this.$emit("pageIndex", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 32px;
  margin: 31px 0;
  display: flex;
  justify-content: center;
  ::v-deep(.btn-next) {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #fff;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 22px;
    font-weight: 400;
  }
  ::v-deep(.btn-prev) {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #fff;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 22px;
    font-weight: 400;
  }
  ::v-deep(.el-pager li) {
    height: 32px;
    width: 32px;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #fff;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
  }
  ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    border: 1px solid #0066ff;
    background-color: #fff;
    border-radius: 2px;
    color: #0066ff;
  }
}
</style>
